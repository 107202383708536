p {
    font-size: 14px;
}
    a{
    text-decoration: none;
}
h2 {
    font-size: 30px;
    margin-bottom: 20px;
    font-weight: 600;
}


.loader-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8F8F8AD;
  }
 .cal-date{
     text-transform: uppercase;
 }
 .digitaltimepicker .rc-time-picker {
    width: 100% !important;
}

.digitaltimepicker.rc-time-picker-clear-icon{
    display: none;
    
}

.input[type="date"].webkit-clear-button {
     display: none;
   }
  @media all and (min-width: 762px) {

	.dropdown-menu li{
		position: relative;
	}
	.dropdown-menu .submenu{ 
		display: none;
		position: absolute;
		right:100% !important; 
		top:-7px;
	}
	.dropdown-menu .submenu-left{ 
		right:100%; left:auto;
	}

	.dropdown-menu > li:hover{ background-color: #f1f1f1 }
	.dropdown-menu > li:hover > .submenu{
		display: block;
	}
}	
@media (max-width: 762px) {

.dropdown-menu .dropdown-menu{
		margin-left:0.7rem; margin-right:0.7rem; margin-bottom: .5rem;
}

}	
  .loader{
    left: 50%;
    top: 30%;
    z-index: 1000;
    position: absolute;
  }
ol, ul{
    padding: 0px;
}
.text-primary {
    color: #5D38DB !important;
}
.btn-outline-success {
    color: #2BE690;
    border-color: #2BE690;
}
.btn-check:active+.btn-outline-success, .btn-check:checked+.btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active{
    background-color:#2BE690  !important ;
    color: #fff;
    border-color: #2BE690 !important;
}
.btn-blue {
    background: #1a81f7;
    border-color: #1a81f7;
    color: #fff;
}
.rounded-btn {
    border-radius: 30px !important;
}
::-webkit-scrollbar {
  width: 10px;


}


::-webkit-scrollbar-track {
  background: rgb(230, 222, 222); 
}
 

::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 3px;
}


::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/*! Firefox */    
html{
    scrollbar-color:  #888 #f1f1f1 ;
}
.form-content.check-frm div {
        padding: 0px 10px;
}
.form-content.check-frm div>div {
    margin-left: 0 !important;
}
.errorMsg{
    color: red;
    font-size: 12px;
    text-transform: lowercase;

}
.errorMsg::first-letter{
    text-transform: capitalize;
}


.swal-text {
    text-transform: lowercase;
    
}
.swal-text::first-letter{
    text-transform: capitalize;
}
.star {
        color: red;
       margin-left: 3px;
        top: -.3em !important;
}
.placeholder:after {
    content: "*";
    color: red;
}

.btn-gray {
    background: #808080;
    color: #fff;
    height: auto !important;
    }
 .btn-gray:hover{
    background: #808080c4;
    color: #fff;
    }
.settings-menu {
    list-style-type: none;
}
.settings-menu li {
    padding: 8px 0px;
    border-bottom: 1px solid #ced4da;
}
.settings-menu li a{
   
color: #212529;
}
.settings-menu li i {
    color: #ced4da;
}
.main-container{
    min-height: 100vH;
    background: #FCFAFA;
    
}
.load-container{
    min-height: 100vH;
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
}
/* .input-container {
  position: relative;
}
.input-container input {
    outline: none;
    position: relative;
    width: 100%;
    height: 40px !important;
    border: 0;
    color: #212121;
    font-size: 17px !important;
    font-weight: 400;
    border: none !important;
    border: 1px solid #bfbfbf !important;
    background: #F1F8FF !important;
    box-shadow: none !important;
    padding: 0;
    padding-left: 10px;
}
.input-container input:focus ~ label {
    left: 0;
    top: -46px;
}
.input-container input:focus ~ .bar:before, .input-container input:focus ~ .bar:after {
  width: 49.5%;
} */
/* .input-container input:valid ~ label {
    left: 0;
    top: -46px;
} */
/* .input-container input:invalid ~ label {
    top: -46px;
    left: 0px;
} */
.input-container textarea:valid ~ label {
    left: 0;
    top: -46px;
}


.input-container label {
    position: absolute;
    top: -4px;
    left: 12px;
    color: #746E6E;
    font-weight: 300;
    line-height: 50px;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
    font-size: 16px !important;
}
.input-container .bar {
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 100%;
  height: 1px;
}
.w-90{
    width: 85%
}
.input-container .bar:before, .input-container .bar:after {
  content: '';
  position: absolute;
  background: #b0b0b0;
  width: 0;
  height: 2px;
  -webkit-transition: .2s ease;
  transition: .2s ease;
}
.input-container .bar:before {
  left: 50%;
}
.input-container .bar:after {
  right: 50%;
}


.login-left.pumb-banner{
    background: url(./img/fuel-banner.png);
    background-size:cover;
}

.login-left {
    min-height: 100vH;
    background: url(./img/banner.png);
    background-size:cover;
}
.window-height {
    min-height: 100vH;

}
.logo-continer {
    display: block;
    width: 212px;
}
.login-banner .btn.btn-info {
    display: block;
    margin: 130px auto 30px;
    background: #3597EC;
    height: 50px;
    line-height: 50px;
    padding: 0;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 7px;
    color: #fff;
    width: 100%;
    max-width: 500px;

}
.login-banner .btn.btn-outline-primary {
    width: 100%;
    border: 2px solid #E0E0E0;
    height: 50px;
    padding: 0;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 50px;
    color: #fff;
    display: block;
    margin: 0px auto 130px;
    width: 100%;
    max-width: 500px;
}
.view-dsr{
    border: 2px solid #E0E0E0;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 14%);
    /* border-right: 9px solid #6F52ED; */
    /* border-left: 3px solid #6F52ED */
    /* border-bottom: transparent;
  
    /* border-top: 9px solid transparent; */
    /* transform: rotate(45deg); */
}
.view-dsr2{
    border-bottom: 1px solid #E0E0E0;
    /* border-right: 9px solid #6F52ED; */

}
/* .btm-corner {
    position: absolute;
    bottom: 30px;
} */
.display-card {
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 14%);
    padding: 10px 12px;
     border-radius: 5px;
     min-height: 150px;
    /* text-align: center; */
}
.display-card h5 {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 22px;
}
.display-card p {
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-bottom: 10px !important;
    word-break: break-all;
}
.display-card small {
    text-align: right;
    display: block;
}
.login-banner h6 {
    color: #fff;
    font-size: 18px;
}
.login-banner .border-top {
    margin: 10px 0;
    border-color: #fff;
}
.login-banner .btn.btn-outline-primary img {
    position: relative;
    top: -5px;
    margin-right: 10px;
    width: 30px;
}
.login-container h2 {
    font-size:30px;
    color: #10101C;
    font-weight: 700;
    margin-bottom: 5px;
}
.login-container p {
    color: #10101C;
    font-size: 16px;
    margin-bottom: 35px;
    font-weight: 500
}
.btn.btn-primary {
    width: 100%;
    height: 60px;
    font-size: 25px;
    font-weight: 700;
    border-radius: 5px;
    background: #5D38DB;
    border: none;
    color: #fff !important;
}
.btn.btn-done {
    float: right;
    font-size: 12px;
    border-radius: 5px;
    background: #5D38DB;
    border: none;
    color: #fff !important;
}
.btn.btn-primary:hover{
    background: #5d38dbeb;
}
.modal .select-container::after {
    top: 9px;
    
    
    
  
  
}
.modal .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
    width: 1em;
    margin-top: 0.50em;
    height: 1em;
   
}
.select-container::after {
    content: '';
    width: 0;
    height: 0px;
    background: transparent;
    position: absolute;
    right: 6px;
    border-right: 9px solid #6F52ED;
    border-bottom: transparent;
    border-left: transparent;
    border-top: 9px solid transparent;
    transform: rotate(45deg);
    top: 12px;
    pointer-events: none;
}
.select-container select {
    height: 40px;
    background: #f1f8ff;
}
.select-container {
    position: relative;
}
.form-container .form-group {
    margin-bottom: 35px;
}
.form-container h4 {
    color: #746E6E;
    text-align: center;
    font-size: 14px;
}
.modal-content{
    border-radius: 10px;
    padding: 20px;
}
.modal-body{
    padding: 0rem 1rem;
}
.modal-body h4 {
    color: #414141;
    font-size: 15px;
    margin: 20px 0;
}
.time{
    color: #FF1C1C;
}
.modal select {
    font-size: 13px;
}
.modal .role input[type="checkbox"] {
    height: auto !important;
    margin-right: 10px;
    margin-bottom: 10px;
}
.role label {
    font-size: 13px;
}
.notes h5 {
    font-size: 16px !important;
    margin-bottom: 10px !important;
}
.notes p {
    font-size: 14px;
}
.form-wrap label {
    font-size: 15px;
}
 input.highlight ,  select.highlight {
    background: #F1F8FF !important;
}
.modal-header .modal-title {
    font-size: 1.25rem;
color: #10101C;
font-weight: 500;
}
.otp-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}
.btn.btn-success {
    background: #2BE690;
    border: none;
    height: 60px;
    width: 100%;
    font-size: 22px;
    font-weight: 700;
    border-radius: 3px;
}

.red {
    color: #FF1C1C;
}
.otp-container .otp {
    height: 110px !important;
    border-radius: 0 !important;
    font-size: 30px !important;
    text-align: center;
    border: 2px solid #E0E0E0;
}
.modal-body h5 {
    color: #414141;
    font-size: 33px;
    margin: 30px 0;
}
.form-container.small .form-group {
    margin-bottom: 35px;
}
.pumb-banner h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 900;
    margin: 100px 0;
}
.btn.btn-secondary{
    background: #CCCCCC;
    border: none;
    color: #434343;
    
    height: 45px;
    line-height: 45px;
}

.section-container h2 {
    color: #10101C;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 15px;;
}
.section-container p {
    color: #10101C;
    font-size: 18px;
}
.section-container .card-header{
    padding: 0px;
}
.card-header {
    text-align: center;
    line-height: 85px;
    background: #FD3155;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
}
.card-info .card-header {
    background: #2DAEFC;
}
.card-warning .card-header {
    background: #FEAF42;
}
.card-success .card-header {
    background: #2BE690;
}
.card.subscription {
    border-radius: 15px;
    overflow: hidden;
    border: none;
    box-shadow: 0px 0px 24px 0px rgba(0,0,0,.1);
    padding-bottom: 30px;
}
.card.subscription ul li {
    font-size: 14px;
    line-height:18px;
    margin-bottom: 10px;
}
.card-header::after {
    background: inherit;
    content: '';
    width: 20px;
    height: 20px;
    left: calc(50% - 10px);
    position: absolute;
    bottom: -7px;
    transform: rotate(45deg);
}
.card.subscription  li {
    color: #747373;
    font-size: 16px;
    line-height: 30px;
}
.card.subscription ul {
    margin-top: 20px;
}
.report h3{
    font-size: 22px;
}
.middle {
    justify-content: center;
    display: flex;
}
.subscription .btn.btn-primary {
    width: 160px;
margin: 0 auto;
display: block;
font-size: 14px;
height: 40px;
}
.subscription .card-body {
    min-height: 310px;
    padding: 5px 10px 5px 30px;
}
.left-wrap {
    background: #5D38DB;
    padding: 25px 35px;
    width: 270px;
    height: 100vh;
    overflow: auto;
    
}
.left-wrap .logo-continer img {
    width: 161px;
}
.menu-line{
    list-style-type: none;
    margin-top:40px; 
}
.menu-line li {
    padding-bottom: 20px;
    border-right: 1px solid #fff;
    padding-right: 40px;
    position: relative;
    padding-top: 10px;
}
.menu-line li:last-child {
    padding-bottom:5px;
}
.menu-line li:last-child::after {
    display: none;
}
.left-wrap a{
    color: #fff;
    text-decoration: none;
}
.left-wrap a img {
    margin-right: 10px;
}
.menu-line p{
    margin-bottom: 0px;
    font-size: 14px; 
    text-align: right;
    /*white-space: nowrap;*/
    min-height: 40px;
}
.steps {
    font-weight: 700;
    font-size: 12px;
    position: absolute;
    right: -12px;
    top: 0px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    background: #fff;
    color: #000;
    border-radius: 50%;
    text-align: center;
    margin-top: 10px;
    transition: 0.3s ease;
}
.steps.active , .steps:hover{
     background: #2BE690;
    color: #fff;
    
}
.side.active , .side:hover{
    /* background: #2BE690; */
   color:  #2BE690
   
}
.menu-line li::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    background: #fff;
    border-radius: 50%;
    right: -5px;
    bottom: 10px;
}
.b-date{
    width: 170%;
}
.content-area {
    width: calc(100% - 270px);
    height: 100vh;
    overflow: auto;
}

.top-menu .dropdown-menu{
    right: 0;
left: auto;
padding: 0px;

}
.top-menu{
background:#F1F8FF !important;
}
.rounded-circle{
    border-radius: 50%;
}
.img-profile {
    height: 1.5rem;
width: 1.5rem;
}
.page-wrap{
    background: #F5F5F5;
}
.navbar-head {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    color: #10101C;
}
.user-head{
    font-size: 15px;
    color: #404040 !important;
}
.user-head img{
    margin-right:10px; 
}
.form-content-sm p {
    font-size: 14px;
    color: #1C1C27;
    line-height: 22px;
}
.list-wrp {
    min-height: 400px;
}
.form-wrap {
    background: #fff;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.2);
    padding: 25px 20px;
    border-radius: 10px;
    max-height: calc(100vH - 250px); 
    /* min-height: 400px; */
    overflow: auto;
   }
.select-container.highlight select {
    background: #F1F8FF;
}
.btn-add {
    width: 27px;
    height: 27px;
    text-align: center;
    background: #5D38DB;
    border: none;
    color: #fff;
    border-radius: 50%;
    line-height: 23px;
    font-weight: 700;
    font-size: 18px;
}
.form-wrap h2 {
    font-size: 25px;
    text-align: center;
}
.form-wrap h6 {
    font-size: 20px;
    text-transform: uppercase;
}
.check-wrap.form-check .form-check-input {
    float: right;
    margin-left: -1.5em;
}
.form-check-input:checked {
    background-color: #2BE690;
    border-color: #2BE690;
}
.form-container {
    margin: 8px 0px ;
        
}
.form-content {
    padding: 60px 0px;
}
.form-content .input-group label , .form-content .input-group input{
       height: auto;
    margin-top: 40px;
    border: 1px solid grey;
    white-space: break-spaces;
}
.form-content .input-group label{
background:#fff;
width: 75%;
}
.form-content .input-group input{
background: #F1F8FF;
border-radius: 0px .25rem .25rem 0px!important;
padding: .375rem .50rem;

}
.form-content-sm input , .form-content-sm select{
    background: #F1F8FF;
    color: #282D32;
    border: 1px solid #BFB8B8;
    height: 35px;
    font-size: 15px;
}
.form-content-sm .select-container::after {
    content: '';
    width: 0;
    height: 0px;
    background: transparent;
    position: absolute;
    right: 22px;
    border-right: 9px solid #6F52ED;
    border-bottom: transparent;
    border-left: transparent;
    border-top: 9px solid transparent;
    transform: rotate(45deg);
    top: 12px;
    pointer-events: none;
}
.form-container .btn {
    font-size: 15px;
    height: 40px;
    font-weight: normal;
    display: inline;
    text-transform: uppercase;
    white-space: nowrap;
}
.form-wrap h5 {
    font-size: 22px;
margin-bottom: 30px;
font-weight: 600;
}
.table-title {
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 600;
    color: #3C4B61;
}
.search span {
    background: transparent;
    border-left: 1px solid #BFB8B8;
    border-top: 1px solid #BFB8B8;
    border-bottom: 1px solid #BFB8B8;
    border-right: none !important;
    background: #fff;
    border-radius: .25rem 0px 0px .25rem;
 }
.search input {
    border-left: none;
        border-left-color: currentcolor;
    border-color: #BFB8B8 !important;
    font-size: 14px;
    background: #fff !important;
}.notify .dropdown-menu {
    width: 300px;
    padding: 0;
    border: none;
    border-radius: 5px;
    max-height: 350px;
    overflow: auto;
    box-shadow:0px 0px 6px 2px rgba(0,0,0,0.2);
}
.dropdown-button{
    display: flow-root;
    /* height: 138%; */
    padding: 0.25rem 1rem;
    clear: both;
    /* font-weight: 400; */
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.time-picker-cell .rc-time-picker-clear {
    display: none;
  }
.time-picker-cell-in .rc-time-picker-input[disabled] {
    color: #000000;
    background: #f7f7f7;
    cursor: not-allowed;
    
}

.digitaltimepicker .rc-time-picker-input[disabled][disabled] {
    color: #090909;
    background: #f7f7f7;
    cursor: not-allowed;
}
.dropdown-item.active, .dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: #E5E3EE;
}
.nav-item.dropdown.notify a {
    margin-right: 15px;
}
.notify-all {
    font-size: 13px !important;
}
.notify .badge {
    position: absolute;
    top: 10px;
    right: 19px;
    font-size: 10px;
    border-radius: 50%;
    padding: 0;
    background: #FF3E3E;
    width: 12px;
    height: 12px;
    line-height: 12px;
    color: #fff;
}
.notify-head {
    font-size: 12px;
    text-transform: uppercase;
    background: #5D38DB;
    color: #fff;
    font-weight: 600;
    padding: 8px 15px;
    margin-bottom: 0px;
    border-radius: 5px 5px 0px 0px;
}
.notify-all{
     font-size: 12px;
}
.dropdown-menu.notification span {
    font-size: 11px;
white-space: normal;
color: #7d7f90;
display: block;
font-weight: 600;
line-height: 14px;
}
.dropdown-menu.notification small {
    color: #a0aebb;
    font-size: 11px;
    font-weight: 700;
    white-space: normal;
}
.dropdown-menu.notification strong {
    font-size: 14px;
    white-space: normal;
}
.dropdown-menu.notification li{
    border-bottom:  1px solid rgba(0,0,0,.15);
}
.dropdown-menu.notification li:first-child , .dropdown-menu.notification li:last-child {
    border: none;
}
 .dropdown-toggle::after {
    display: none;
}

.user-head span {
    border: 1px solid #565656;
border-radius: 50%;
width: 15px;
height: 15px;
line-height: 13px;
display: inline-block;
text-align: center;
font-weight: 700;
margin-left: 15px;
vertical-align: middle;
font-size: 11px;
}
/* .table-widget{
    overflow: auto;
    max-height: calc(100vh - 300px);
} */
.table-widget thead tr {
    border: 1px solid #C7C7C7;
    background: #F4F7FB;
   
}
.table-widget thead tr th{
    color: #10101C;
font-size: 13px;
font-weight: 400;
        padding: 5px;
        border-bottom: none;
        position: relative;
        border-left: 1px solid #C7C7C7;
        vertical-align: middle;
      
}
.table-widget tbody td {
    font-size: 14px;
    font-weight: 400;
    color: #1C1D1D;
        padding: 5px;
        border-bottom: 1px solid #dee2e6  !important;
        border-left:1px solid #dee2e6  !important;
        vertical-align: middle;
}
tr.purchased {
    background: #fffbe8;
}
tr.same-size {
    background: #EEFFD0;
}
tr.loose-qnty {
    background: #F2EEEE;
}
/* .table-widget thead tr th::after {
    border-left: 1px solid #C7C7C7;
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    height:100%;
} */
/* .table-widget thead tr th:last-child::after {
    display: none;
} */

.table-widget .btn.btn-success{
    background: #D8F6EC;
    color: #00B87E;
    font-size: 13px;
}
.table-widget .btn.btn-primary{
    background: #D6DDF9;
    color: #5A7BFD !important;
    font-size: 13px;
}
.table-widget td .btn{
    width: 100px;
height: 33px;
/* display: block; */
}
.table-widget .table td,.table-widget .table th{
    border-top: none;
}
.table-widget .table .no-content {
    padding: 100px !important;
    color: #DEDEDE !important;
}
.icon-btn button {
    background: transparent;
    border: none;
    font-size: 18px;
    color: #1C1D1D;
}
.icon-btn button:hover{
    color: #5D38DB;
}
.dropdown li a {
    font-size: 14.5px;
}
.dropdown li a i{
    color: #000;
}
.form-content-sm .card {
    margin: 0px 150px !important;
}
.link-text{
    font-size: 14px;margin-bottom: 5px;
    position: relative;
    font-weight: 500;
}
.link-text:after {
    position: absolute;
    content: '\f061';
    font-family: 'FontAwesome';
    right: -20px;
    top: 0px;
}
.link-text:hover{
    text-decoration: underline;
}
.modal .btn {
    height: auto;
    width: 50%;
    line-height: normal;
    font-size: 15px;
}
.modal-header{
    border:none;
    text-transform: uppercase;
    font-size: 20px;
    
}
.modal-footer p {
    font-size: 13px;
}
.modal-footer{
border:none;
justify-content: center;
}
.modal input[type=text] {
    height: 30px;
    font-size: 13px;
}
input[type="date"] {
    position: relative;
}

 input[type=date] {
     text-transform: uppercase;
     font-size: 14px;
}


input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

/* adjust increase/decrease button */
/* input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
} */
.modal input[type=text] {
    height: 35px;
    font-size: 13px;
}
.modal .select-container select {
    height: 35px;
    background: #fff;
    font-size: 13px;
    padding: 0px 6px;
}
.modal label {
    color: #746E6E !important;
font-weight: 300;
margin-bottom: 1px;
transition: 0.2s ease;

    color: #10101C;
    font-weight: 400;
    font-size: 14px;
    padding: 0px;
}
.modal .form-group {
    margin-bottom: 10px !important;
}
.modal-dialog-xl , .modalxl div{
    max-width: 800px;
}
.modal-wrapper .modalm {
    max-width: 600px!important;
}
.modal-wrapper .modalxxl {
    max-width: 1000px !important;
}
.modal-footer .btn {
    display: block;
    min-width: 200px;
    height: 35px;
    text-transform: uppercase;
    font-size: 16px;
}
.select-container.highlight select {
    background: #F1F8FF;
}
.form-wrap .select-container select {
    height: auto;
    background: #fff;
    font-size: 14px;
}
/*balancesheet*/
.balace-sheetwrap h2 {
    font-size: 26px;
    font-weight: 800;
}
.balace-sheetwrap h3 {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    margin-bottom: 20px !important;
}
.balace-sheetwrap h4 {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    color: #3c1aae;
    letter-spacing: 1px;
}
.balace-sheetwrap h5 {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
}
.balace-sheetwrap .sheet-wrp {
    border-top: 2px dashed #dfdfdf;
    padding-top: 20px;
}
.table{
    overflow: auto; 
    min-height: 50px;
    max-height: 200px;
}
table.table.table-bordered.sheet-table td, table.table.table-bordered.sheet-table th {
    padding: 7px 10px!important;
}
.balace-sheetwrap table.table.table-bordered td, table.table.table-bordered th {
    padding: 2px 10px !important;
}

.balace-sheetwrap table.table.table-bordered {
    border: 1px solid #e1e1e1;
}
.balace-sheetwrap.form-wrap h6 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 800;
    margin-left: 0 !important;
    padding-left: 0;
    padding-right: 0;
}





@media (max-width:762px) {
 .form-wrap{
        max-height: none;
 }   
    .content-area {
    width: 100%;
    height: auto;
}
/* .table-widget {
    overflow: scroll;
} */
    .left-wrap{
        width: 100%;
    }
    .menu-line {
        display: flex;
        margin-top: 40px;
}
.responsive{
    overflow: auto;
}
.menu-line li{
    border-bottom: 1px solid #fff;
    border-right:none;
}

.steps {
    position: absolute;
    top: auto;
    right: 50%;
    bottom: -15px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    color: #000;
    border-radius: 50%;
    text-align: center;
    z-index: 999;
}
.menu-line li::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    right: 20px;
    bottom: -4px;
}
.left-wrap{
    padding: 20px 0px;
}

    .form-wrap h2 {
    font-size: 25px;
    text-align: center;
}
.form-content {
    padding: 50px 0px;
}
.navbar-nav{
    flex-direction: row;
}
.user-head {
    font-size: 15px;
    color: #fff !important;
}
.left-wrap .logo-continer img {
    width: 120px;
}
.dropdown-menu.notification {
    width: 100%;
}
.dropdown-menu a{
    color: #10101C;
}
.logo-continer {
    display: block;
    width: 190px;
}
.notify .badge {

    right: 12px;

}
}
@media(max-width: 1300px){
    .form-content-sm .card {
        margin: 0px 80px !important;
    }  
} 
.otp {
	
	color: blue,
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
  }
  
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
   height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  .modal-wrapper .modal {
    
    width: 100%;
    height: auto;
    overflow: hidden;
    outline: 0;
}
  .modal-wrapper  .modal {
    z-index: 100;
    background: white;
    position: relative;
    margin: 1.75rem auto;
    border-radius: 10px;
    max-width: 500px;
    padding: 20px;
    
  }
  .modal-wrapper .modal{
      display: block !important;
  }
  .modal-header {
    display: flex;
    justify-content: flex-end;
    padding: 0.25rem 1rem;
  }
  .modal-wrapper .modal-header {
    padding: 0.25rem 0rem;
}
  .modal-wrapper .modal-header button {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    padding: .5rem .8rem;
    margin: -.5rem -.5rem -.5rem auto;
}
.modal-wrapper .modal-header button:focus {
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
    opacity: 1;
}
.modal-wrapper .modal-header button span {
    display: none;
}
.modal-wrapper .form-check-input[type=radio]:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
    background-color: #2BE690;
    border-color: #2BE690;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.modal-wrapper .modalxl {
    max-width: 800px !important;
}

.modal-wrapper .modalxlnozzle {
    max-width: 1500px !important;
  
}

.modal-wrapper .modalxlcreditsale {
    max-width: 1096px !important;
    
}

.debit{
    float: right;
    font-weight: bold;

}
.credit
{
    float: right;
    font-weight: bold;
}
.balance-content{
    padding:10px 11px 5px 28px;
    float:left;
    width:48%;
    border-top: 2px solid #000;
    border-right: 1px solid #000;

}
.balance-para{
    width: 100%;
    font-size: 22px;
    font-weight: bold;
    text-align: center;

}
.asset{
    float: left;
    font-weight: bold;

}
.asset-para{
    float: left;
    width: 48%;

}
.liability{
    float: left;
    font-weight: bold;

}
.p-lib{
    width: 48%;
    float: right;

}
.tool-tip{
position: "absolute";
 top: "10%";
  right: "0";
  background-color: white !important;
  color: black  !important;
}
.note{
    font-size: 12px;
}
.container {
    flex: 1;
    /* backgroundColor: "#fff",
    alignItems "center",
    justifyContent: "center", */
    /* background-color:white; */
    align-items:"center" ;
    justify-content: "center";

}
.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    height: 50%;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
  }
  .custommodal.mymodal {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    border: 0;
    background: 0;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0;
    margin-top: 1.75rem;
    MAX-WIDTH: 500PX;
}
.custommodal.mymodal .modal-footer .btn {
   
    line-height: 24px;
    height: 45px;
    font-size: 18px;
}
  
  .myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .rackpopup .modal-dialog {
    max-width: 700px;
}
.btn-add.btn-danger{
    background:#b02a37
}

/*New pages*/
.sidemenu {
    list-style-type: none;
    margin-top: 30px;
}
ul.sidemenu li i {
    margin-right: 5px;
}
.sidemenu li.active .sidesubmenu li.active p , .sidemenu li .sidesubmenu p:hover{
    background: #2BE690 !important;
}
.sidesubmenu {
    list-style-type: none;
    margin-top: 10px;
}
/* .sidesubmenu li {
    padding: 2px 3px;
         
  } */
  .sidemenu li p{
    padding: 8px 20px;
}

.sidemenu svg {
    width: 22px;
    margin-right: 10px;
}
.sidemenu li p{
    font-size: 14px;
    margin: 0px;
}
.profile-pic {
    text-align: center;
   
    width: 100%;
    height: 80px;
  
}
.bi-arrow-bar-right {
   max-width: 100px;
}
.profile-pic img {
    width: 80px;
    height: 80px;
   
    border-radius: 50%;
}
.sidemenu li.active p , .sidemenu li p:hover{
    background: #2BE690;
}

.sidemenu li.active .sidesubmenu li p{
    background: transparent;
    }
.sidemenu li .sidesubmenu p {
    padding: 8px 15px;
}
.logo-wrp{
        padding: 25px 20px;
    text-align: center;
}
.top-menubar li {
    padding: 0px 12px;
}
.top-menubar li p {
    margin: 0;
}
.sheet-wrp h5 {
    font-size: 18px;
}
.sheet-wrp p {
    font-size: 14px;
    font-weight: 500;
}
.page-wraper{
    background: #F1F8FF !important;
}
.top-menus {
    background: #F5F5F5;
}
/* .page-wraper .form-wrap{
    max-height: 525px;
} */
/*dsr*/
.sheet-table{
    font-size: 13px;
}
.table.sheet-table>thead {
    vertical-align: middle;
    text-align: center;
}
.page-wraper .table-widget .table th {
    font-weight: 600;
}
.page-wraper .table-widget .table td, .page-wraper .table-widget .table th {
    border-top: none;
    text-align: center;
}
.page-wraper .table-widget .btn.btn-primary {
   background: #5D38DB;
  
    color: #fff !important;
    font-size: 13px;
}
.sidebar-end table{
    font-size: 14px;
}
.sidebar-end table td{
    padding: .5rem .5rem;
    background-color: var(--bs-table-bg);
    background-image: linear-gradient(var(--bs-table-accent-bg),var(--bs-table-accent-bg));
    border-bottom-width: 1px;
}
table i:hover {
    color: #5D38DB;
}
.page-wraper .form-wrap label {
    font-size: 14px;
    font-weight: 600;
}
.page-wraper .form-container .btn {
    font-size: 14px;
    height: 35px;
}
/* Loarder */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #1136ee;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/*Nozzle allocation*/

.form-container .nav-tabs .nav-item.show .nav-link {
    
    border: none;
    color: #fff;
    border-radius: 5px;
}
.form-container .nav-tabs  .nav-link.active
{
    background-color: #5D38DB;
    color: #fff;
}
.form-container .nav-tabs  .nav-link:hover{
 background-color: #5D38DB;
    color: #fff;
}
.form-container .nav-tabs .nav-link{
    font-size: 14px;
    background-color: #fff;
    padding: 5px 4px;
    margin: 3px;
    border-radius: 5px;
    border-color: #5D38DB;
    color: #5D38DB;
}
.tab-view .form-wrap{
    max-height: 100vh;
    overflow: auto;
}
.tab-view .form-wrap.sidebar-end {
    max-height: calc(100vH - 180px);
}
table.status-table .btn-xs{
    font-size: 13px;
    height: 30px;
}
table.status-table .btn-xs.btn-warning{
     background: #f6f2d8;
    border-color: #f6f2d8;
    color: #cfce6f;
   }
   table.status-table .btn-xs.btn-success{
    background: #d8f6ec;
   border-color: #d8f6ec;
   color: #00b87e;
  }
  table.status-table .btn-xs.btn-danger{
    background: #fee1e3;
   border-color: #fee1e3;
   color: #e15e66;
  }

  .dash-card {
    background: #fff;
    box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 20%);
    padding:  30px 20px;
    border-radius: 10px;
  }
  .circle-bar{
      /* display: block; */
      word-break: break-word;
  }
  .dash-card p{
      margin-bottom: 0px;
  } 
  .dash-card.d-cart{
    padding-left: 10px;
    padding-right: 10px;
} 

  
  .dash-card .table-widget thead tr th ,.dash-card .table-widget tbody td{
    border-left: none !important;
    text-align: left !important;
   
  }
 
  .btn-grp {
    background: #F4F7FB;
    padding: 10px 15px;
    
}
.btn-grp button:nth-child(1) {
    background: #f99371;
}
.btn-grp button:nth-child(2) {
    background: #699e96;
}
.btn-grp button:nth-child(3) {
    background: #9558dc;
}
.btn-grp button:nth-child(4) {
    background: #59c886;
}
.btn-grp button:nth-child(5) {
    background: #fcb95b;
}
.action-link i {
    border: 1px solid #dee2e6;
    border-radius: 50%;
    padding: 3px 5px;
}
.denomination .accordion-button {
    height: 30px;
    color: #fff;
    background: #2BE690;
    border-color: #2BE690;
    text-align: center;
    font-weight: 700;
    padding: 1rem 1.25rem 1rem 4.25rem;
}

.denomination .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #2BE690;
    
    border-color: #2BE690;
}
.denomination .accordion-button::after{
    content: "\f107" !important;
    font: normal normal normal 14px/1 FontAwesome;
    background-image: none !important;
    font-size: 20px;
}
.denomination table.border-0 tbody td{
    border:none !important;
}
.fond{position:absolute;top:calc(50% - 10px);left:0; right:0;bottom:0;}

.contener_general
{
  -webkit-animation:animball_two 2.3s infinite;
  -moz-animation:animball_two 2.3s infinite;
  -ms-animation:animball_two 2.3s infinite;
  animation:animball_two 2.3s infinite;
  width:44px; height:44px;
}
.contener_mixte
{
  width:44px; height:44px; position:absolute;
}
.ballcolor
{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.ball_1, .ball_2, .ball_3, .ball_4
{
  position: absolute;
  -webkit-animation:animball_one 2.3s infinite ease;
  -moz-animation:animball_one 2.3s infinite ease;
  -ms-animation:animball_one 2.3s infinite ease;
  animation:animball_one 2.3s infinite ease;
}
.ball_1
{
  background-color:#FD3155;
  top:0; left:0;
}
.ball_2
{
  background-color:#FEAF42;
  top:0; left:24px;
}
.ball_3
{
  background-color:#5D38DB;
  top:24px; left:0;
}
.ball_4
{
  background-color:#2BE690;
  top:24px; left:24px;
}

@-webkit-keyframes animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}
@-moz-keyframes animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}
@-ms-keyframes animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}
@keyframes animball_one
{
  0%{ position: absolute;}
  50%{top:12px; left:12px; position: absolute;opacity:0.5;}
  100%{ position: absolute;}
}

@-webkit-keyframes animball_two
{
  0%{-webkit-transform:rotate(0deg) scale(1);}
  50%{-webkit-transform:rotate(360deg) scale(1.3);}
  100%{-webkit-transform:rotate(720deg) scale(1);}
}
@-moz-keyframes animball_two
{
  0%{-moz-transform:rotate(0deg) scale(1);}
  50%{-moz-transform:rotate(360deg) scale(1.3);}
  100%{-moz-transform:rotate(720deg) scale(1);}
}
@-ms-keyframes animball_two
{
  0%{-ms-transform:rotate(0deg) scale(1);}
  50%{-ms-transform:rotate(360deg) scale(1.3);}
  100%{-ms-transform:rotate(720deg) scale(1);}
}
@keyframes animball_two
{
  0%{transform:rotate(0deg) scale(1);}
  50%{transform:rotate(360deg) scale(1.3);}
  100%{transform:rotate(720deg) scale(1);}
}
.sheet-wrp tr .sub_head {
    padding-left: 20px;
    font-weight: normal;
}
.balace-sheetwrap h5{
    text-transform:uppercase;
    line-height: 25px;
        letter-spacing: 1px;
}
.addfield-wrp{
    position: relative;
}
.btn-grp-fxd{
    position: absolute;
}
.round-graph svg {
    width: 100%;
    margin-top: 10px;
}
.round-graph {
    height: 330px;
    overflow-x: auto;
}
.img-container {
    border-bottom: 2px solid #eee;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:35px;
}
.success-msg{
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-msg .btn.btn-primary {
    width: 100%;
    height: 45px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    background: #5D38DB;
    border: none;
    color: #fff !important;
}
.success-msg p{
  font-size: 16px;
  margin:15px 0px 20px 0px;
  }
 .round-graph .CircularProgressbar .CircularProgressbar-text {
     font-size: 11px;

}
.graph-color > div {
    padding: 5px;
}
.graph-color > div:nth-child(3n+1) circle {
    fill: #fca374 !important;
}
.graph-color > div:nth-child(3n+2) circle{
    fill :#8dc5a6 !important;
}
.graph-color > div:nth-child(3n+3) circle { 
    fill: #8026f1 !important;
} 
.btn.btn-violet{
    background-color: #c393f9;
    color: #fff
}
.btn-grp a:nth-child(1) {
    background: #f99371;
    display: block;
}
.btn-grp a:nth-child(2) {
    background: #699e96;
    display: block;
}
.btn-grp a:nth-child(3) {
    background: #9558dc;
    display: block;
}
.btn-grp a:nth-child(4) {
    background: #59c886;
    display: block;
}
.btn-grp a:nth-child(5) {
    background: #fcb95b;
    display: block;
}
.recharts-wrapper > .recharts-surface {
    width: 100%;
    margin-left: -18px;
}
.dash-card.line-graph .recharts-wrapper {
    width: 100% !important;
  min-height:300px !important;
}
.m-btn{
    font-size: 15px!important;
}
.eq-ht {
    min-height: 520px;
    margin: 10px;
}
.to-account{
    height: 100% !important;
}
.btn-primary.bt-search
{
    width: 62px !important;   
    padding: 10px !important;
    font-size: 13px !important;
}
.table-card .table-widget{
    height: 270px;
    margin-top: 30px !important;
}
.dash-card.line-graph{
    margin-top: 23px !important;
}
.dash-card.table-card{
    margin-top: 23px !important;
}

/* .hide-arrow.input::-webkit-inner-spin-button  {
    -webkit-appearance: none;
    margin: 0;
  } */
  /* .hide-arrow input[type=number] {
    -moz-appearance: textfield;
  } */
  
 /* .hide-arrow{
     -moz-appearance: textfield;
 }
 .hide-arrow::-webkit-inner-spin-button{
     display:none;
 }
 .hide-arrow::-webkit-inner-spin-button,
 .hide-arrow::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
 } */
    /* input[type=number].hide-arrow::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
     } */
     input[type='number'] {
        -moz-appearance:textfield;
    }
    
    input::-webkit-outer-spin-button,
    input.hide-arrow::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    .accordion-overlay{
        position: absolute;
    max-height: 300px;
    overflow: scroll;
    background: rgb(255, 255, 255) none repeat scroll 0% 0%;
    }
    /* .modal {
        z-index: 9;
    } */
    .moztime{
        
        -moz-appearance: auto;
    }
    .print{
        padding: 20px;
    }
    .btn.btn-green{
        background-color: green;
    }
    .modalxl.ht-modal {
        height: 470px;
        overflow: auto;
    }
    
  
    .pagination {
      
        display: flex;
        list-style: none;
        outline: none;
       }
       
        
       .pagination>.active>a {
        background-color: #0d6efd;
        color: #fff;
       }
        
       .pagination>li>a {
        border: 1px solid #0d6efd;
        padding: 5px 10px;
        outline: none;
        cursor: pointer;
       }
       .pagination>li> {
       text-decoration: none;
       border: 1px solid #dee2e6;
       }
       i.fa.fa-arrow-down.other_tanker_details {
        cursor: pointer;
    }
    .rc-time-picker-input[disabled]{
        color: #0a0a0a;
    }
    
    /* input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button {
        color: #fff;
        position: relative;
      }
      
      input[type="date"]::-webkit-datetime-edit-year-field{
        position: absolute !important;
        border-left:1px solid #8c8c8c;
        padding: 2px;
        color:#000;
        left: 56px;
      }
      
      input[type="date"]::-webkit-datetime-edit-month-field{
        position: absolute !important;
        border-left:1px solid #8c8c8c;
        padding: 2px;
        color:#000;
        left: 26px;
  
      }
      
      
      input[type="date"]::-webkit-datetime-edit-day-field{
        position: absolute !important;
        color:#000;
        padding: 2px;
        left: 4px;
       
        
      } */